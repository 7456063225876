import React from 'react';
import { Grid, IconButton, LinearProgress, Step, StepLabel, Tooltip } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import arrowDown from "react-useanimations/lib/arrowDown";
import alertTriangle from "react-useanimations/lib/alertTriangle";
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { Done, Lock, LockOpen } from "@material-ui/icons";
import moment from "moment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { hourBySecond } from "../../../Tool/convert";
import { theme } from "../../../App";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";
import Countdown from "./Component/Countdown";
import ButtonComponent from "../../../Component/ButtonComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EventNoteIcon from '@material-ui/icons/EventNote';
import organism from "../../Administrator/Organism/Organism";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function Registration(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [moduleEppPdf, setModuleEppPdf] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [registration, setRegistration] = React.useState({ formation: {}, chapters: [], resourceGroups: [] });
    const [countdownFinish, setCountdownFinish] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [totalDuration, setTotalDuration] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const ResourceRef = React.useRef(null);

    const goCourse = (course) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_course', { registration: props.match.params.id, course: course.id }),
                state: { courseType: course.type }
            });
        }, 800);
    };
    const goSurveySatisfaction = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_satisfaction', { registration: props.match.params.id })
            });
        }, 800);
    };
    const goSurveyPrePost = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_pre_post', { registration: props.match.params.id, type: type })
            });
        }, 800);
    };

    const goSurveyEpp = (type) => {
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_survey_epp', { registration: props.match.params.id, type: type })
            });
        }, 800);
    };

    const goVirtualClassroom = (joinUrl, virtualClassroom) => {
        saveCompletedVirtualClassroom(virtualClassroom);
        window.open(joinUrl, "_blank");
        /*
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_virtual_classroom', {registration: props.match.params.id, virtualClassroom: virtualClassroom})
            });
        }, 800);*/
    };
    const goVirtualClassroomReplay = (ReplayUrl, virtualClassroom) => {
        window.open(ReplayUrl, "_blank");
        /*
        setShow(false);
        setTimeout(() => {
            props.history.push({
                pathname: getRoutePathname('learner_registration_virtual_classroom_replay', {registration: props.match.params.id, virtualClassroom: virtualClassroom})
            });
        }, 800);*/
    };


    React.useEffect(() => {

        if (props.match.params.id) {

            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'learner_registration_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setTotalDuration(response.data.formation.duration);
                        setRegistration(response.data);

                        // TODO a supp prochainement
                        //setSeconds(response.data.timeSpentByChapterAndSurvey)
                        setSeconds(response.data.timeSpent)

                        setBreadcrumbs({
                            currentPage: response.data.formation.name,
                            links: [
                                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' }
                            ]
                        });

                        // TODO a supp prochainement
                        if (response.data.hasSurveyEpp && response.data.isFinishPreEpp === false) {
                            if (response.data.pathPreEpp || response.data.pathPostEpp) {
                                if (response.data.courseDoneCount >= 1 || !(response.data.state === 5 || response.data.state === 3)) {
                                    setModuleEppPdf(false)
                                }
                                else {
                                    setModuleEppPdf(true)
                                }

                            }
                        }

                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_home'));
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        if (registration && registration.formation && registration.formation.id && theme.id === 2) {
            window.dataLayer.push({
                'event': 'eec.detail',
                'duree_formation': ((registration.formation.duration / 60) / 60) + " heures",
                'format_formation': "E-learning",
                'titre_formation': registration.formation.name,
                'numero_formation': registration.formation.id,
                'type_page': "Portail"
            })
        }

    }, [registration]);

    let disabled = !(registration.state === 1 || registration.state === 3 || registration.state === 5); // TODO A voir

    const saveCompletedVirtualClassroom = (id) => {

        Api.post({
            route: 'learner_registration_virtual_classroom_add',
            data: { "id": id },
            params: { registration: props.match.params.id }
        }, (response) => {
            if (response.status === 200) {
                //TODO ? Valid progress bar virtualClassroom
            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur',
                        variant: 'error',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });

    };

    const stayTime = (duration, timeSpent, type) => {
        const stayTimeSeconds = duration - timeSpent;
        const hours = Math.floor(stayTimeSeconds / 3600);
        const minutes = Math.floor((type === 1 ? stayTimeSeconds : (stayTimeSeconds % 3600)) / 60);
        const remainingSeconds = stayTimeSeconds % 60;

        const time = type === 1 ? minutes + ' min ' + remainingSeconds + 's' : hours + ' heures ' + minutes + 'm ' + remainingSeconds + 's restant';

        return (
            ((timeSpent !== 0) && (duration !== 0)) &&
            <span style={{ background: ((timeSpent === duration) || (stayTimeSeconds <= 0)) ? '#6FD08C' : '#69ABAA', fontWeight: 'bold', color: '#FFF', padding: '3px 10px', borderRadius: 6, fontSize: 11, margin: '0 5px', display: 'flex', gap: 5, alignItems: 'center', flexWrap: 'wrap' }}>
                {((timeSpent === duration) || (stayTimeSeconds <= 0)) ? 'Temps validé' : time} <AccessTimeIcon style={{ fontSize: 16 }} /> {type === 2 && ' (temps DPC)'}
            </span>
        )
    }
    const [isBoxPopupTransition, setIsBoxPopupTransition] = React.useState(false);
    const divRef = React.useRef(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsBoxPopupTransition(false)
                    }
                    else setIsBoxPopupTransition(true);
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.3,
            }
        );

        if (divRef.current) observer.observe(divRef.current);
    }, [loading]);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    React.useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const countdownFinishCallback = (childData) => {
        setCountdownFinish(childData);
    };

    const scrollToResource = () => {
        if (ResourceRef.current) {
            ResourceRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <div style={{ textAlign: 'center', margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader} style={{ height: '100%', position: 'relative' }}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <div>
                            <div style={{
                                position: 'fixed',
                                right: 20,
                                zIndex: 10,
                                top: 90,
                                transform: !isBoxPopupTransition ? 'translate(100%)' : 'translate(0%)',
                                opacity: !isBoxPopupTransition ? '0' : '1',
                                visibility: !isBoxPopupTransition ? 'hidden' : 'visible',
                                transition: 'all .4s',
                            }}>
                                { (parseInt(registration.formation.financeTypeId) !== 6) && stayTime(totalDuration, registration.timeSpentByChapterAndSurvey, 2)}
                            </div>
                            <div
                                style={{
                                    backgroundImage: registration.formation ? 'linear-gradient(0deg, rgba(1,4,49,.5) 0%, rgba(1,4,49,.5) 100%), url(' + learnerHomeFormationImageBanner + registration.formation.image : '',
                                    backgroundColor: '#00000096',
                                    backgroundSize: 'cover',
                                    width: '100%',
                                    color: '#fff',
                                    minHeight: 160
                                }}>
                                <div style={{ width: isSmallView ? '90%' : ((screenReducer.screen === 'XL') ? '70%' : '80%'), margin: 'auto', padding: '25px 0px' }}>
                                    <h1 style={{ marginTop: 20, textAlign: 'left', margin: 0, padding: '10px 0px 25px 0px' }}>{registration.formation.name}</h1>
                                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}><AccessTimeIcon className={classes.cardStateIcon} /> {registration.formation.duration / 3600 + ' Heures'} {(parseInt(registration.formation.financeTypeId) !== 6) && <span ref={divRef}>{stayTime(totalDuration, registration.timeSpentByChapterAndSurvey, 2)}</span>}</span>
                                        <span style={{ display: 'flex', alignItems: 'center' }}><AccountBalanceIcon className={classes.cardStateIcon} /> {registration.formation.financeType}</span>
                                        {(registration.state === 1) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#28a745' }} className={classes.cardStateIcon} />Disponible</span>}
                                        {(registration.state === 2) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#17a2b8' }} className={classes.cardStateIcon} />Bientôt disponible</span>}
                                        {(registration.state === 3) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#6c757d' }} className={classes.cardStateIcon} />Terminée</span>}
                                        {(registration.state === 4) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#ffc107' }} className={classes.cardStateIcon} />ANDPC en attente</span>}
                                        {(registration.state === 5) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#6c757d' }} className={classes.cardStateIcon} />Terminée</span>}
                                        {(registration.state === 6) && <span className={classes.cardState}><CheckCircleOutlineIcon style={{ backgroundColor: '#ffc107' }} className={classes.cardStateIcon} />Financement en attente</span>}
                                        {(registration.state === 7) && <span className={classes.cardState}><ErrorOutlineIcon style={{ backgroundColor: '#dc3545' }} className={classes.cardStateIcon} />Conflit de paiement</span>}
                                        
                                        {(registration.startAt && registration.endAt) && <span style={{ display: 'flex', alignItems: 'center' }}><AccessTimeIcon className={classes.cardStateIcon} /> Session : {moment(registration.startAt).format('ll')} au {moment(registration.endAt).format('ll')}</span>}
                                    </div>
                                    <br />
                                </div>
                            </div>

                            <Fade in={show} {...{ timeout: 1000 }} style={{ maxWidth: 1240 }}>
                                <>
                                    <Grid container spacing={3} style={{ width: '95%', margin: 'auto' }}>
                                        {(registration.resourceGroups.length > 0 && (registration.state === 1 || registration.state === 3 || registration.state === 5) && screenReducer.screen === 'XL') &&
                                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                                <div className={classes.resourceSticky}>
                                                    <div style={{ background: '#f6f6f6', borderBottom: '1px solid #000' }}>
                                                        <h3 style={{ margin: 0, padding: 10, fontSize: 14 }}>Ressources</h3>
                                                    </div>
                                                    {registration.resourceGroups.map((resourceGroup, index) => (
                                                        <div key={index} style={{ padding: '10px 20px' }}>
                                                            <p style={{ fontWeight: 700, marginBottom: 10, marginTop: 0, textAlign: 'left', fontSize: 12 }}>{resourceGroup.name}</p>
                                                            {resourceGroup.resources.map((resource, i) => (
                                                                <div key={i} style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? { color: '#bbb', fontSize: 11, lineHeight: '18px', textAlign: 'left' } : { color: theme.colorPrimary, fontSize: 11, lineHeight: '18px', textAlign: 'left' }}>
                                                                    <MediaLinkComponent
                                                                        style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? { color: '#bbb', fontSize: 11, lineHeight: '18px' } : { color: theme.colorPrimary, fontSize: 11, lineHeight: '18px', cursor: 'pointer' }}
                                                                        path={resource.path}
                                                                        resourceId={resource.id}
                                                                        registration={props.match.params.id}
                                                                        type={resource.type}
                                                                        history={props.history}
                                                                        disabled={disabled || (registration.state !== 5 && resourceGroup.id === 1)}
                                                                    >
                                                                        {'- ' + resource.name}
                                                                    </MediaLinkComponent>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    ))}
                                                </div>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10} style={{ margin: 'auto' }}>
                                            <div>
                                                <Grid container spacing={2} style={{ width: '100%', margin: 'auto' }}>
                                                    {registration.formation.description &&
                                                        <div style={{ textAlign: 'left', fontSize: 15 }}>
                                                            <h2 style={{ marginTop: 10 }}>Introduction</h2>
                                                            <div className={classes.noMargin} dangerouslySetInnerHTML={{ __html: registration.formation.description }} />
                                                        </div>}

                                                    {registration.formation.objective &&
                                                        <div style={{ textAlign: 'left', fontSize: 15 }}>
                                                            <h2 style={{ marginTop: 10 }}>Objectifs pédagogiques</h2>
                                                            <div dangerouslySetInnerHTML={{ __html: registration.formation.objective }} />
                                                        </div>}

                                                    {registration.formation.financeType === 'ANDPC' &&
                                                        <div style={{ textAlign: 'left', fontSize: 15 }}>
                                                            <h2 style={{ marginTop: 10 }}>Instructions</h2>
                                                            <p style={{ margin: '10px 0' }}>Vous devez obtenir un résultat supérieur à 75 % pour avoir accès au prochain cours.</p>
                                                        </div>}

                                                    {(screenReducer.screen !== 'XL') && (registration.resourceGroups.length > 0) && (registration.state === 1 || registration.state === 3 || registration.state === 5) &&
                                                        <p
                                                            onClick={scrollToResource}
                                                            className={classes.link}
                                                            style={{ color: theme.colorPrimary, fontSize: 18, fontWeight: 600 }}
                                                        >
                                                            <EventNoteIcon style={{ verticalAlign: 'middle', marginRight: 5 }} />Consulter les ressources de cette formation
                                                        </p>
                                                    }
                                                </Grid>
                                                <br />
                                                {
                                                    (registration.state === 1 || registration.state === 3 || registration.state === 5) ?

                                                        <div style={{ background: '#fff', boxShadow: '0 0 3px 0 rgba(0,0,0,0.25)', borderRadius: 5, overflow: 'hidden' }}>
                                                            <h3 style={{ textAlign: 'left', padding: '15px 0px 0px 20px', margin: 0 }}>Votre progression</h3>
                                                            <div style={{ width: '90%', display: 'flex', justifyContent: 'space-evenly', gap: 22, margin: 'auto' }}>
                                                                <div className={classes.progression}>
                                                                    <LinearProgress style={{ width: '100%', padding: 2, margin: '7px 0' }} variant="determinate" value={registration.courseDoneCount * 100 / registration.courseCount} />
                                                                    <span style={{ fontWeight: 700 }}>Modules terminés </span> : {registration.courseDoneCount} / {registration.courseCount}
                                                                </div>
                                                                <div className={classes.progression}>
                                                                    <LinearProgress style={{ width: '100%', padding: 2, margin: '7px 0' }} variant="determinate" value={seconds * 100 / totalDuration >= 100 ? 100 : seconds * 100 / totalDuration} />
                                                                    {/*<span style={{ fontWeight: 700 }}>Temps passé </span> : {seconds > 0 ? hourBySecond(seconds) : '0s'} sur {hourBySecond(totalDuration)}*/}
                                                                    <span style={{ fontWeight: 700 }}>Temps passé </span> : {`${hours}h${minutes}m${remainingSeconds}s`} sur {hourBySecond(totalDuration)}

                                                                </div>
                                                            </div>
                                                            <br />

                                                            {/* FC endAt */}
                                                            {
                                                                registration.formation.isEppFormat &&
                                                                <div style={{ margin: 10, padding: 10, border: 1, borderRadius: 20, color: '#FFF', background: '#c8dfe5' }}>
                                                                    <p style={{ margin: 0, padding: 10, color: '#000', fontWeight: 600 }}>
                                                                        Vous avez jusqu'au {registration.fcEndAt ? moment(registration.fcEndAt).format('ll') : 'Null'} pour terminer le module Évaluation des pratiques professionnelles : temps 1 et la Formation continue.
                                                                    </p>
                                                                </div>
                                                            }
                                                            {/* FC endAt */}

                                                            {
                                                                (registration.hasVirtualClassroom && registration.unlockVirtualClassroom) &&
                                                                Object.keys(registration.virtualClassroomsInfos).map((id, index) => (
                                                                    registration.virtualClassroomsInfos[id].active ?
                                                                        <div className={classes.virtualClassroom} key={index}>
                                                                            {
                                                                                !registration.virtualClassroomsInfos[id].meetingFinish ?
                                                                                    countdownFinish ?
                                                                                        <>
                                                                                            <p className={classes.virtualClassroomIntro}>Rejoindre la classe vitruelle :</p>
                                                                                            <div className={classes.buttonGroup}>

                                                                                                <ButtonComponent label={'Rejoindre'} onClick={() => {
                                                                                                    goVirtualClassroom(registration.virtualClassroomsInfos[id].joinUrl, registration.virtualClassroomsInfos[id].id)
                                                                                                }} disabled={loading} />
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <Countdown callback={countdownFinishCallback} unixEndDate={moment(registration.virtualClassroomsInfos[id].meetingAt).valueOf() / 1000} />
                                                                                    :
                                                                                    <>
                                                                                        <p className={classes.virtualClassroomIntro}>La classe virtuelle s'est déjà tenue.</p>
                                                                                        {
                                                                                            registration.virtualClassroomsInfos[id].replayUrl != '' ?
                                                                                                <>
                                                                                                    <p>Pour rejouer cette classe virtuelle, cliquez sur le bouton ci-dessous :</p>

                                                                                                    <ButtonComponent label={'Visionner'} onClick={() => {
                                                                                                        goVirtualClassroomReplay(registration.virtualClassroomsInfos[id].replayUrl, registration.virtualClassroomsInfos[id].id)
                                                                                                    }} disabled={loading} />
                                                                                                </>
                                                                                                :
                                                                                                <p>La rediffusion de cette classe virtuelle sera prochainement disponible.</p>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                ))
                                                            }
                                                            {
                                                                (registration.isFinishBeforeDeploymentEpp && registration.pathPreEpp) ?
                                                                    <>
                                                                        <div className={classes.chapterEPP}>EPP temps 1</div>
                                                                        <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                            <Step active={true} completed={true}>
                                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                                    <MediaLinkComponent
                                                                                        style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                                                                                        path={registration.pathPreEPP}
                                                                                        type={1}
                                                                                        disabled={false}
                                                                                    >EPP temps 1
                                                                                    </MediaLinkComponent>
                                                                                </StepLabel>
                                                                            </Step>
                                                                        </Stepper>
                                                                    </>
                                                                    :
                                                                    (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                                    <>
                                                                        <div className={classes.chapterEPP}>Évaluation des pratiques professionnelles : temps 1</div>

                                                                        {
                                                                            (registration.inProgressFormation && !registration.isFinishPreEpp) &&
                                                                            <div style={{ margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: '#ffc107' }}>
                                                                                <p style={{ margin: 0, textAlign: 'center', color: '#000' }}><b>Nouvelle règlementation</b></p>
                                                                                <UseAnimations animation={alertTriangle} size={80} wrapperStyle={{ margin: 'auto' }} />

                                                                                <p style={{ margin: 0, padding: '10px', color: '#000' }}>
                                                                                    À la suite de la nouvelle réglementation concernant le questionnaire des critères d’évaluation des pratiques professionnelles (EPP),
                                                                                    nous vous invitons à le refaire pour reprendre votre formation là où vous en étiez.
                                                                                </p>
                                                                                <p style={{ color: '#000' }}>
                                                                                    <UseAnimations animation={arrowDown} size={30} wrapperStyle={{ display: 'inline-block', verticalAlign: 'middle' }} />
                                                                                    Cliquez sur le lien ci-dessous
                                                                                    <UseAnimations animation={arrowDown} size={30} wrapperStyle={{ display: 'inline-block', verticalAlign: 'middle' }} />
                                                                                </p>
                                                                            </div>
                                                                        }
                                                                        <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                            <Step active={registration.unlockSurveyPreEpp} completed={registration.unlockSurveyPreEpp && registration.isFinishPreEpp}>
                                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                                    {registration.unlockSurveyPreEpp ?
                                                                                        <span className={classes.linkRegistration} style={{ display: 'block', textAlign: 'left' }} onClick={() => goSurveyEpp("pre")}>
                                                                                            Audit clinique : grille d’évaluation 1
                                                                                        </span> : <span style={{ color: '#999', display: 'block', textAlign: 'left' }}>
                                                                                            Audit clinique : grille d’évaluation 1
                                                                                        </span>
                                                                                    }
                                                                                    <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left' }}> {registration.durationEpp.durationEpp1 != 0 ? " | temps : " + Math.floor((registration.durationEpp.durationEpp1) / 60) + " min" : ''}</span>
                                                                                    {
                                                                                        // {(parseInt(registration.formation.financeTypeId) !== 6) && stayTime(registration.durationEpp.durationEpp1, registration.timeSpentEpp.timeSpentEpp1, 1)}
                                                                                    }
                                                                                </StepLabel>
                                                                            </Step>
                                                                        </Stepper>
                                                                    </>
                                                            }
                                                            {
                                                                registration.hasSurveyPre &&
                                                                <>
                                                                    <div className={classes.chapter}>Bilan pré-formation</div>
                                                                    <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                        <Step active={registration.unlockSurveyPre} completed={registration.unlockSurveyPre && registration.isFinishPre}>
                                                                            <StepLabel StepIconComponent={StepIcon}>
                                                                                {registration.unlockSurveyPre ? <span className={classes.linkRegistration} onClick={() => goSurveyPrePost("pre")}>
                                                                                    Questionnaire pré-formation
                                                                                </span> : <span style={{ color: '#999' }}>
                                                                                    Questionnaire pré-formation
                                                                                </span>
                                                                                }
                                                                                <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left' }}> {registration.durationPrePost.durationPre != 0 ? " | temps : " + Math.floor((registration.durationPrePost.durationPre) / 60) + " min" : ''}</span>
                                                                                {
                                                                                    // {(parseInt(registration.formation.financeTypeId) !== 6) && stayTime(registration.durationPrePost.durationPre, registration.timeSpentPrePost.timeSpentPre, 1)}
                                                                                }
                                                                            </StepLabel>
                                                                        </Step>
                                                                    </Stepper>
                                                                </>
                                                            }
                                                            {registration.chapters.map((chapter, index) => (
                                                                <div key={chapter.id} style={{ background: (chapter.isUnitEPP2 && !chapter.isUnitEPP2Unlock) ? '#f4f4f4' : '' }}>

                                                                    {index === registration.chapters.findIndex((val) => val.isUnitEPP2 && !val.isUnitEPP2Unlock) && (
                                                                        <p style={{ paddingTop: 15, fontWeight: 600, fontSize: 15 }}>
                                                                            La section Évaluation des pratiques professionnelles 2 n'est pas disponible avant le {registration.eppLastStageAt}
                                                                        </p>
                                                                    )}

                                                                    <div className={classes.chapter}>
                                                                        {chapter.name}
                                                                        <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left', }}>{chapter.duration ? ' | temps total : ' + Math.floor((chapter.duration) / 60) + ' min' : ''}</span>
                                                                    </div>

                                                                    <Stepper orientation={'vertical'} className={classes.styleStepper} style={{ background: (chapter.isUnitEPP2 && !chapter.isUnitEPP2Unlock) ? '#f4f4f4' : '' }} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} style={{ background: (chapter.isUnitEPP2 && !chapter.isUnitEPP2Unlock) ? '#f4f4f4' : '' }} />}>
                                                                        {chapter.courses.map((course) => {
                                                                            const minutes = Math.floor((course.duration) / 60);
                                                                            const seconds = course.duration % 60;
                                                                            const duration = minutes != 0 ? minutes + ' min' : seconds != 0 ? seconds + ' s' : '0s'
                                                                            return (
                                                                                <Step key={course.id} active={(course.unlock || course.forcedUnlock)} completed={course.done}>
                                                                                    <StepLabel StepIconComponent={StepIcon}>
                                                                                        {(course.unlock || course.forcedUnlock) ? <span className={classes.linkRegistration} onClick={() => goCourse(course)}>
                                                                                            {course.name}
                                                                                        </span> : <span style={{ color: '#999' }}>
                                                                                            {course.name}
                                                                                        </span>
                                                                                        }
                                                                                        <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left' }}> {course.duration ? " | temps : " + duration : ''}</span>
                                                                                        {
                                                                                            // {(parseInt(registration.formation.financeTypeId) !== 6) && stayTime(course.duration, course.timeSpentCourse, 1)}
                                                                                        }
                                                                                        <span style={{ margin: '0 0 0 auto', color: '#999' }}>
                                                                                            {course.score > 0 && '(Score : ' + course.score + ' %)'}
                                                                                        </span>
                                                                                    </StepLabel>
                                                                                </Step>
                                                                            )
                                                                        })}
                                                                    </Stepper>
                                                                </div>
                                                            ))}
                                                            {
                                                                registration.hasSurveyPost &&
                                                                <>
                                                                    <div className={classes.chapter}>Bilan post-formation</div>
                                                                    <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                        <Step active={registration.unlockSurveyPost} completed={registration.unlockSurveyPost && registration.isFinishPost}>
                                                                            <StepLabel StepIconComponent={StepIcon}>
                                                                                {registration.unlockSurveyPost ? <span className={classes.linkRegistration} onClick={() => goSurveyPrePost("post")}>
                                                                                    Questionnaire post-formation
                                                                                </span> : <span style={{ color: '#999' }}>
                                                                                    Questionnaire post-formation
                                                                                </span>
                                                                                }
                                                                                <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left' }}> {registration.durationPrePost.durationPost != 0 ? " | temps : " + Math.floor((registration.durationPrePost.durationPost) / 60) + " min" : ''}</span>
                                                                                {
                                                                                    // {(parseInt(registration.formation.financeTypeId) !== 6) && stayTime(registration.durationPrePost.durationPost, registration.timeSpentPrePost.timeSpentPost, 1)}
                                                                                }
                                                                            </StepLabel>
                                                                        </Step>
                                                                    </Stepper>
                                                                </>
                                                            }
                                                            {
                                                                (registration.isFinishBeforeDeploymentEpp && registration.pathPostEpp) ?
                                                                    <>
                                                                        <div className={classes.chapterEPP}>EPP temps 2</div>
                                                                        <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                            <Step active={true} completed={true}>
                                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                                    <MediaLinkComponent
                                                                                        style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                                                                                        path={registration.pathPostEpp}
                                                                                        type={1}
                                                                                        disabled={false}
                                                                                    >EPP temps 2
                                                                                    </MediaLinkComponent>
                                                                                </StepLabel>
                                                                            </Step>
                                                                        </Stepper>
                                                                    </>
                                                                    :
                                                                    (registration.hasSurveyEpp && registration.hasQuestionEpp) &&
                                                                    <>

                                                                        <div className={classes.chapterEPP}>Évaluation des pratiques professionnelles : temps 2</div>
                                                                        {
                                                                            (!registration.unlockEppLastStage) &&
                                                                            <div style={{ margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: '#afd5d5' }}>
                                                                                <AccessTimeIcon style={{ margin: "auto", display: "block", fontSize: 39, color: "#000" }} />
                                                                                <p style={{ margin: 0, padding: '10px', color: '#000' }}>
                                                                                    L'accès à votre second audit clinique sera débloqué à la date du <b>{registration.eppLastStageAt}</b> pour vous laisser le temps de réajuster vos pratiques selon les recommandations personnalisées qui vous ont été transmises suite à votre premier audit. Ce délai est imposé par l'Agence nationale du DPC et conditionne le financement de votre formation.
                                                                                </p>
                                                                            </div>
                                                                        }
                                                                        <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                            <Step active={registration.unlockSurveyPostEpp && registration.unlockEppLastStage} completed={registration.unlockSurveyPostEpp && registration.unlockEppLastStage && registration.isFinishPostEpp}>
                                                                                <StepLabel StepIconComponent={StepIcon}>
                                                                                    {registration.unlockSurveyPostEpp && registration.unlockEppLastStage ?
                                                                                        <span className={classes.linkRegistration} onClick={() => goSurveyEpp("post")}>
                                                                                            Audit clinique : grille d’évaluation 2
                                                                                        </span> : <span style={{ color: '#999', display: 'block', textAlign: 'left' }}>
                                                                                            Audit clinique : grille d’évaluation 2
                                                                                        </span>
                                                                                    }
                                                                                    <span style={{ color: '#5E6E82', fontSize: 13, textaAlign: 'left' }}> {registration.durationEpp.durationEpp2 != 0 ? " | temps : " + Math.floor((registration.durationEpp.durationEpp2) / 60) + " min" : ''}</span>
                                                                                    {
                                                                                        // {(parseInt(registration.formation.financeTypeId) !== 6) && stayTime(registration.durationEpp.durationEpp2, registration.timeSpentEpp.timeSpentEpp2, 1)}
                                                                                    }
                                                                                </StepLabel>
                                                                            </Step>
                                                                        </Stepper>
                                                                    </>
                                                            }
                                                            {
                                                                registration.pathPostEPP &&
                                                                <>
                                                                    <div className={classes.chapterEPP}>Questionnaire de satisfaction</div>
                                                                    <Stepper orientation={'vertical'} className={classes.styleStepper} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                        <Step active={registration.unlockSurveySatisfaction} completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}>
                                                                            <StepLabel StepIconComponent={StepIcon}>
                                                                                {registration.unlockSurveySatisfaction
                                                                                    ? <span className={classes.linkRegistration} onClick={() => goSurveySatisfaction()}>
                                                                                        Questionnaire de satisfaction
                                                                                    </span> : <span style={{ color: '#999' }}>
                                                                                        Questionnaire de satisfaction
                                                                                    </span>
                                                                                }
                                                                            </StepLabel>
                                                                        </Step>
                                                                    </Stepper>
                                                                </>
                                                            }
                                                            {
                                                                registration.pathHonorCertificate &&
                                                                <>
                                                                    <div className={classes.chapterEPP}>Attestation sur l'honneur</div>
                                                                    <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                        <Step active={true} completed={true}>
                                                                            <StepLabel StepIconComponent={StepIcon}>
                                                                                <MediaLinkComponent
                                                                                    style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                                                                                    path={registration.pathHonorCertificate}
                                                                                    type={1}
                                                                                    disabled={false}
                                                                                >Attestation sur l'honneur
                                                                                </MediaLinkComponent>
                                                                            </StepLabel>
                                                                        </Step>
                                                                    </Stepper>
                                                                </>
                                                            }
                                                            {
                                                                registration.hasSurveySatisfaction &&
                                                                <>
                                                                    <div className={classes.chapter}>Questionnaire de satisfaction</div>
                                                                    <Stepper orientation={'vertical'} connector={<StepConnector orientation={'vertical'} className={classes.stepConnector} />}>
                                                                        <Step active={registration.unlockSurveySatisfaction} completed={registration.unlockSurveySatisfaction && registration.isFinishSurveySatisfaction}>
                                                                            <StepLabel StepIconComponent={StepIcon}>
                                                                                {registration.unlockSurveySatisfaction
                                                                                    ? <span className={classes.linkRegistration} onClick={() => goSurveySatisfaction()}>
                                                                                        Questionnaire de satisfaction
                                                                                    </span> : <span style={{ color: '#999' }}>
                                                                                        Questionnaire de satisfaction
                                                                                    </span>
                                                                                }
                                                                            </StepLabel>
                                                                        </Step>
                                                                    </Stepper>
                                                                </>
                                                            }
                                                        </div>
                                                        :

                                                        <>
                                                            {registration.state === 4 ?
                                                                <div style={{ fontSize: 14, textAlign: 'left' }}>
                                                                    <h3>Finalisez votre inscription</h3>
                                                                    <p>Votre inscription à la formation <span className={classes.strongText}>{registration.formation.name}</span> d’une durée de {registration.formation.duration / 3600} heures à bien été prise en compte mais n’est pas encore finalisée.</p>
                                                                    <p>Afin de la compléter, veuillez suivre les étapes suivantes : </p>
                                                                    <ol>
                                                                        <li>Rendez-vous sur: <a style={{ color: theme.colorPrimary }} href='https://www.agencedpc.fr/professionnel/login' target="_blank">https://www.agencedpc.fr/professionnel/login</a>, connectez-vous ou créez un compte.</li>
                                                                        <li>Cliquez sur la rubrique « Inscription »</li>
                                                                        <li>Dans le menu à gauche, cliquez sur « Rechercher actions »</li>
                                                                        <li>Dans le champ « Référence de l’actions » copiez/collez le code suivant :
                                                                            <span className={classes.strongText}>{registration.formation.codeANDPC}</span>
                                                                            <Tooltip title="Copier le code">
                                                                                <IconButton aria-label="copy" size="small"
                                                                                    onClick={() => {
                                                                                        navigator.clipboard.writeText(registration.formation.codeANDPC);
                                                                                        dispatch(
                                                                                            SnackbarOpen({
                                                                                                text: 'Code copié dans le presse papier',
                                                                                                variant: 'success',
                                                                                            })
                                                                                        );
                                                                                    }}>
                                                                                    <FileCopyIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </li>
                                                                        <li>Cliquez sur « Détail action de DPC » puis sélectionnez une session et finalisez votre inscription.</li>
                                                                    </ol>
                                                                    <p className={classes.strongText}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.<br />Notre équipe reste à votre disposition pour tout renseignement complémentaire. </p>
                                                                </div>
                                                                :
                                                                    registration.state === 7 ? 
                                                                    <div style={{margin: '20px 10px 0 10px', padding: '10px', border: '1px', borderRadius: '20px', color: 'white', background: '#ff9800cc'}}>
                                                                        <p style={{margin: 0, padding: '10px', color: '#5E6E82', fontWeight: 700}}>
                                                                            Nous rencontrons quelques difficultés avec votre paiement. Veuillez contacter notre équipe pour résoudre le problème. <br /><br />Cordialement, L'équipe {theme.name}
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    <p className={classes.strongText}>Nous vous remercions d’avoir choisi cette formation, vous serez très prochainement recontacté(e) par notre équipe afin de finaliser votre inscription. </p>
                                                            }
                                                            <br />
                                                            <div style={{ fontSize: 14, textAlign: 'left' }}>
                                                                <h3>Nous contacter </h3>
                                                                <span>Par email : <a style={{ color: theme.colorPrimary }} href="mailto:contact@santeformapro.com">contact@santeformapro.com</a></span>
                                                                <br />
                                                                <span>Par téléphone : 04 42 17 90 82  </span>
                                                            </div>
                                                        </>
                                                }
                                                <br />

                                                {(registration.resourceGroups.length > 0 && (registration.state === 1 || registration.state === 3 || registration.state === 5) && screenReducer.screen !== 'XL') &&
                                                    <div ref={ResourceRef}>
                                                        <h3>Ressources de la formation</h3>
                                                        <Grid container spacing={2}>
                                                            {registration.resourceGroups.map((resourceGroup, index) => (
                                                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                                                                    <p style={{ fontWeight: 700, marginBottom: 10, textAlign: 'left' }}>{resourceGroup.name}</p>
                                                                    {resourceGroup.resources.map((resource, i) => (
                                                                        <div key={i} style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? { color: '#bbb', fontSize: 14, lineHeight: '20px', textAlign: 'left' } : { color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', textAlign: 'left' }}>
                                                                            <MediaLinkComponent
                                                                                style={disabled || (registration.state !== 5 && resourceGroup.id === 1) ? { color: '#bbb', fontSize: 14, lineHeight: '20px' } : { color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                                                                                path={resource.path}
                                                                                resourceId={resource.id}
                                                                                registration={props.match.params.id}
                                                                                type={resource.type}
                                                                                history={props.history}
                                                                                disabled={disabled || (registration.state !== 5 && resourceGroup.id === 1)}
                                                                            >
                                                                                {resource.name}
                                                                            </MediaLinkComponent>
                                                                        </div>
                                                                    ))}
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </div>}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </>
                            </Fade>
                        </div>
                }
            </div>
        </>

    );
}

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    chapterEPP: {
        color: '#e62469',
        padding: 8,
        fontSize: 18,
        paddingLeft: 15,
        textAlign: 'left',
        '& + div': {
            background: '#fff',
            textAlign: 'left'
        }
    },
    chapter: {
        color: '#4AA3A2',
        padding: 8,
        fontSize: 18,
        paddingLeft: 15,
        textAlign: 'left',
        '& + div': {
            background: '#fff',
            textAlign: 'left'
        }
    },
    styleStepper: {
        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 3
        },
    },
    stepConnector: {
        background: '#fff',
        padding: 0,
        marginLeft: 16,
        height: 18,
        '& .MuiStepConnector-line': {
            height: 5,
            width: 1,
            border: 0,
            backgroundColor: '#c0c0c3',
            borderRadius: 1,
        }
    },
    cardState: {
        color: '#FFFFFF',
        borderRadius: 30,
        display: 'initial',
    },
    cardStateText: {
        fontWeight: 700,
        padding: '3px 5px',
        fontSize: 14,
        display: 'initial',
    },
    cardStateIcon: {
        fontSize: 19,
        width: 17,
        height: 19,
        verticalAlign: 'bottom',
        borderRadius: 20,
        marginRight: 5
    },
    linkRegistration: {
        cursor: 'pointer',
        color: theme.colorPrimary,
        '&:hover': {
            color: '#3f51b5',
        }
    },
    virtualClassroom: {
        marginBottom: '20px',
        padding: '10px',
        background: '#f8f8f8',
        borderRadius: '5px',
        textAlign: 'center'
    },
    virtualClassroomIntro: {
        margin: 0,
        fontWeight: 'bold'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    whiteBox: {
        padding: '25px 50px',
        background: '#fff',
        border: '1px solid #F2F2F2',
        borderRadius: 10,
        margin: '10px 10px 10px 0px',
        fontSize: 17,
        fontWeight: 600
    },
    progression: {
        fontSize: 15,
        width: '90%',
        '& .MuiLinearProgress-root': {
            height: 8,
            borderRadius: 10
        },
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#c8dfe5'
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#4f93a5'
        }
    },
    link: {
        cursor: 'pointer',
        fontSize: 13,
        textAlign: 'left',
        '&:hover': {
            opacity: 0.6
        }
    },
    strongText: {
        fontSize: 13,
        fontWeight: 700,
        color: '#5E6E82',
        textAlign: 'left'
    },
    resourceSticky: {
        overflow: 'auto',
        background: '#fff',
        boxShadow: '0 0 3px 0 rgba(0,0,0,0.25)',
        borderRadius: 5,
        width: '100%',
        position: 'sticky',
        padding: 0,
        margin: 0,
        top: 13,
    },
    noMargin: {
        '& p': {
            margin: 0
        }
    }
});
const useStepIconStyles = (theme) => makeStyles(({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 32,
        height: 32,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        '& svg': {
            fontSize: 18,
        }
    },
    active: {
        backgroundImage: 'linear-gradient(136deg, ' + theme.colorPrimary + ' 0%, ' + theme.colorPrimary + ' 40%, ' + theme.colorPrimary + ' 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    lock: {
        backgroundImage: 'linear-gradient(136deg, #8C8C8C 0%, #8C8C8C 40%, #8C8C8C 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage: 'linear-gradient(136deg, #6FD08C 0%, #6FD08C 40%, #28a745 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }
}));
const StepIcon = (props) => {
    const classes = useStepIconStyles(theme)();
    const { active, completed } = props;
    const icons = [<Lock />, <LockOpen />, <Done />];
    let icon;

    if (active && completed) {
        icon = icons[2];
    }
    else if (active) {
        icon = icons[1];
    }
    else {
        icon = icons[0];
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.lock]: !active,
                [classes.completed]: completed,
            })}
        >
            {icon}
        </div>
    );
}

export default Registration;
